.ContatoMain {
  scroll-behavior: smooth;
}

.contatoFirstContainer {
  justify-content: space-between;
  padding-top: 64px;
  gap: 40px
}

.contatoFirstContainerText {
  max-width: 508px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  text-align: center;
}

.contatoFirstContainerText > h2 {
  font-size: 36px;
  line-height: 150%;
}

.contatoFirstContainerText > .nossosCanaisContainer {
  margin-top: 76px;
}

.nossosCanaisContainer {
  display: flex;
  flex-direction: column;
  gap: 32px
}

.contactHeading {
  font-size: 32px;
  text-align: center;
  font-weight: 700;
}

.ContatoMain > .nossosCanaisContainer {
  padding: 0 6%;
  margin-top: 72px;
  margin-bottom: 80px;
}

.nossosCanaisContainer > .textAndImage img {
  width: 24px;
  height: auto;
}

.nossosCanaisContainer > .textAndImage p {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.contatoLastContainer {
  justify-content: space-between;
  gap: 100px;
  padding: 0 4%;
  margin-bottom: 140px;
}

.googleMapsCollum {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 48px;
}

.googleMapsCollum > h4 {
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
  margin: 0;
  margin-top: 16px;
}

.googleMapsCollum > .textAndImage {
  text-align: start;
  justify-content: start;
  align-items: flex-start;
}

.googleMapsCollum > .textAndImage p {
  font-size: 20px;
  line-height: 24px;
}

.iframeContainer > iframe {
  width: 100%;
  height: auto;
  border: none;
  border-radius: 6px;
}

.contactFormContainer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
  /* border-radius: 20px;
  border: 2px solid #E36925; */
  padding: 14px 0px 48px;
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 25px;
  border: 5px solid #E36925;
  border-radius: 6px;
}

.contactFormContainer > legend {
  position: absolute;
  text-align: center;
  top: -25px; 
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;
  width: 280px;
  background-color: #FFFEFC;;
}

.contactFormContainer > p {
  font-size: 12px;
  line-height: 14.5px;
  text-align: center;
  margin-top: 14px;
}

.contactForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  box-sizing: border-box;
  padding-left: 34px;
  padding-right: 34px;
}

.inputLabel { 
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #0D1234;
  gap: 4px;
}

.inputAndIcon {
  display: flex;
  flex-direction: row;
  background-color: #FAFAFA;
  border: 2px solid #D1D5DB;
  border-radius: 6px;
  padding: 12px 16px;
  gap: 8px
}

.inputAndIcon > input, .inputLabel > textarea {
  background-color: #FAFAFA;
  border: none;
  font-family: 'Inter';
  font-size: 16px;
  line-height: 24px;
  max-width: 80%;
}

.inputLabel > textarea {
  padding: 12px 16px;
  background-color: #FAFAFA;
  border: 2px solid #D1D5DB;
  border-radius: 6px;
  max-width: 100%;
  resize: none;
  height: 140px;
}

.inputAndIcon > input:focus, .inputLabel > textarea:focus {
  outline: none;
}

#checkboxInputLabel {
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
  gap: 12px;
}

#checkboxInputLabel > p {
  font-size: 12px;
  line-height: 18px;
}

.contactForm > button {
  width: 100%;
  color: #FAFAFA;
  padding: 8px 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  font-size: 16px;
}


@media (min-width: 1024px) {
  .contatoFirstContainer {
    padding: 80px 10% 0 12%;
    gap: 19.6%;
  }

  .contatoFirstContainerText {
    margin-top: 20px;
    text-align: start;
  }

  .contatoFirstContainerText > h2 {
    font-size: 48px;
  }

  .contatoFirstContainer > .imageContainer {
    width: 40%;
  }

  .contactHeading {
    font-size: 36px;
    text-align: start;
  }

  .contatoLastContainer {
    padding: 108px 10% 0 12%;
    justify-content: space-between;
    /* gap: 19.6% */
  }

  .googleMapsCollum {
    text-align: start;
  }

  .contactFormContainer {
    gap: 44px;
    max-width: 40%;
  }

  .contactFormContainer > p {
    font-size: 16px;
    line-height: 21px;
  }

  .contactFormContainer > legend {
    padding: 0 24px;
    width: 295px;
  }
}