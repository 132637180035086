
@font-face {
  font-family: 'Inter';
  src: url('./Media/Font/Inter-Regular.eot');
  src: url('./Media/Font/Inter-Regular.eot?#iefix') format('embedded-opentype'),
      url('./Media/Font/Inter-Regular.woff2') format('woff2'),
      url('./Media/Font/Inter-Regular.woff') format('woff'),
      url('./Media/Font/Inter-Regular.ttf') format('truetype'),
      url('./Media/Font/Inter-Regular.svg#Inter-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./Media/Font/Montserrat-Regular.eot');
  src: url('./Media/Font/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
      url('./Media/Font/Montserrat-Regular.woff2') format('woff2'),
      url('./Media/Font/Montserrat-Regular.woff') format('woff'),
      url('./Media/Font/Montserrat-Regular.ttf') format('truetype'),
      url('./Media/Font/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./Media/Font/Roboto-Regular.eot');
  src: url('./Media/Font/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
      url('./Media/Font/Roboto-Regular.woff2') format('woff2'),
      url('./Media/Font/Roboto-Regular.woff') format('woff'),
      url('./Media/Font/Roboto-Regular.ttf') format('truetype'),
      url('./Media/Font/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


body {
  margin: 0;
  width: 100%;
  height: 100%;
}

:root {
  background-color: #FFFEFC;
  font-family: 'Inter' !important;
}

h1, h2, h3 {
  font-family: 'Montserrat' !important;
  margin: 0;
  line-height: 150%;
}

p {
  margin: 0;
  line-height: 150%;
 }

button {
  font-family: 'Roboto';
  cursor: pointer;
  border: none;
}

.smallerText {
  font-size: 14px;
  line-height: 150%;
}

.desktopHeaderItens > .smallText {
  font-size: 14px;
  line-height: 24px;
}

.smallText {
  font-size: 16px;
  line-height: 24px;
}

.smallBtn {
  max-width: 36px !important;
  max-height: 36px !important;
  min-width: 36px !important;
  min-height: 36px !important;
  background-color: #E36925;
  border-radius: 4px;
  color: #FFFEFC;
}

.biggerText {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.headingText {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.01em;
  font-weight: 600;
}

.smallBtn:hover, .standardBtn:hover {
  transition: 0.5s;
  background-color: #c9500f
}

.standardBtn {
  padding: 8px 8px;
  background-color: #E36925;
  border-radius: 4px;
  color: #FFFEFC;
}

.pageNameText {
  color: #032851;
  letter-spacing:  -0.01em;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
}

.buttonWithArrow {
  color: #FFFEFC;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  transition: 0.5s;
  gap: 8px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.16));
}

.buttonWithArrow > p {
  margin: 0 !important;
  font-size: 18px;
  line-height: 24px;
}

.buttonWithArrow > img {
  width: 20px;
  height: 20px;
}

.whiteText {
  color: #FFFEFC;
}

.blueBtn {
  background-color: #0D1234;
}

.blueBtn:hover {
  background-color: #0a1244;
}

.orangeBtn {
  background-color: #E36925;
}

.orangeBtn:hover {
  background-color: #a3440d;
}

.whiteBtn {
  background-color: #FFF;
  color: #0D1234;
}

.weirdBtn {
  background-color: transparent;
  border: 2px solid #FFFFFF;
}

.imageContainer > img {
  width: 100%;
  height: auto;
  border-radius: 6px;
}

.standardContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 21px 0 21px;
}

.blueText {
  color: #0D1234;
}

.orangeText {
  color: #E36925;
}

.textAndImage {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.bgDecorationContainer {
  background-image: url('./Media/ContentPattern.svg');
  background-repeat: no-repeat;
  background-size: auto 70%;
}

#anchorNoStyle {
  text-decoration: none;
}

#floatingWhatsappText {
  position: sticky;
  max-width: 120px;
  color: #E36925;
  font-size: 20px;
  font-weight: 700;
  right: 1px;
  margin-left: auto;
  bottom: 85px;
}

@media (min-width: 1024px) {

  .bgDecorationContainer {
    background-repeat: repeat-y;
  }

  .standardContainer {
    padding: 0 7.5% 0 7.5%;
  }

  .pageNameText {
    font-size: 48px;
  }

  .headingText {
    font-size: 36px;
    line-height: 56px;
  }

  .biggerText {
    font-size: 24px;
    line-height: 28px;
  }

  .buttonWithArrow {
    padding: 16px 24px 16px 24px;
  }

  .buttonWithArrow > p {
    font-size: 18px;
    line-height: 28px;
  }
  
  .buttonWithArrow > img {
    width: 24px;
    height: 24px;
  }

  .smallerText {
    font-size: 18px;
  }

  .standardContainer {
    flex-direction: row;
  }

  .textAndImage {
    gap: 16px;
  }
  
}