.SobreMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.aboutFirstContainer {
  background-image: url('../../Media/ContentPattern.svg');
  background-repeat: no-repeat;
  gap: 44px;
  padding-top: 68px;
  background-size: auto 70%;
  justify-content: space-between;
  margin-bottom: 64px;
}

.aboutFirstTextContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  gap: 20px;
}

.aboutHeaderText {
  font-weight: 800;
  line-height: 150%;
  letter-spacing: -0.01em;
  font-size: 36px;
}

.SobreMain > h2 {
  color: #0D1234;
}

.SobreMain > h2, .SobreMain > p {
  margin: 0px 60px;
  text-align: center;
}

.aboutSecondContainer {
  gap: 44px;
  margin-top: 64px;
  margin-bottom: 80px;
  justify-content: center;
}

.aboutSecondContainer > div {
  border-radius: 6px;
  padding: 42px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;
}

.aboutSecondContainer > div img {
  width: 56px;
  height: auto;
  margin-bottom: 10px;
}

.aboutSecondContainer > div h3 {
  font-size: 24px;
  line-height: 29px;
}

.aboutThirdContainer {
  margin-top: 70px;
  margin-bottom: 200px;
  gap: 53px;
  padding: 0 32px;
}

.dutiesContainer {
  display: flex;
  flex-direction: column;
}

.dutiesContainer > div img {
  width: 32px;
  height: auto;
}

.dutiesContainer > div p {
  font-size: 16px;
  line-height: 19px;
}

.dutiesContainer > p {
  width: 100%;
  box-sizing: border-box;
  padding-left: 44px;
  font-size: 12px;
  line-height: 150%;
}

.theBestsContainer > .textAndImage img {
  width: 32px;
  height: auto;
}

.theBestsContainer > .textAndImage p {
  font-size: 16px;
  line-height: 19px;
}

.theBestsContainer > .textAndImage {
  margin-bottom: 40px;
}

.ourValuesDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding-bottom: 72px;
}

.ourValuesDiv > img {
  width: 56px;
  height: auto;
}

.ourValuesDiv > h3 {
  margin-bottom: 40px;
  font-size: 24px;
  line-height: 29px;
}

.valuesDiv {
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding-left: 63px;
  padding-right: 63px;
}

@media (min-width: 1024px) {
  .aboutFirstContainer {
    padding-right: 5.4%;
    gap: 16%;
    background-repeat: repeat-y;
  }

  .aboutFirstContainer > .imageContainer {
    min-width: 43%;
  }

  .aboutFirstTextContainer {
    text-align: start;
    max-width: 40%;
    padding-top: 58px;
    padding-bottom: 135px;
  }

  .aboutHeaderText {
    font-size: 56px;
  }

  .aboutSecondContainer {
    gap: 3%;
    margin-top: 80px;
  }

  .aboutSecondContainer > div {
    gap: 24px;
    width: 30%;
  }

  .ourValuesDiv > img {
    width: 72px;
    height: auto;
  }

  .aboutSecondContainer > div img {
    width: 72px;
    height: auto;
    margin-bottom: 16px;
  }

  .aboutThirdContainer {
    gap: 12.5%;
    margin-top: 88px;
    margin-bottom: 176px;
    padding: 0 16%;
  }

  
  .dutiesContainer > div img {
    width: 40px;
    height: auto;
  }
  
  .dutiesContainer > div p {
    font-size: 20px;
    line-height: 30px;
  }
  
  .dutiesContainer > p {
    padding-left: 56px;
    padding-right: 40px;
  }
  /* .dutiesContainer {
    max-width: 41%;
  }

  .theBestsContainer {
    max-width: 40%;
  } */

  .theBestsContainer > .textAndImage img {
    width: 40px;
    height: auto;
  }

  .theBestsContainer > .textAndImage p {
    font-size: 20px;
    line-height: 30px;
  }

  .ourValuesDiv {
    padding-bottom: 80px;
  }

  .valuesDiv {
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5%;
    padding-left: 14%;
    padding-right: 14%;
  }

  .valuesDiv > div {
    width: 25%;
    margin-bottom: 73px;
  }

}

