.ComplianceTextPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 21px 70px 21px;
  gap: 64px;
  background-image: url('../../Media/ContentPattern.svg');
  background-repeat: no-repeat;
}

.textPageStart {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-bottom: 40px;
}

.textAndHeadingContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

@media (min-width: 1024px) {
  .ComplianceTextPage {
    align-items: flex-start;
    padding: 137px 7.5% 140px 7.5%;
  }
  
  .textPageStart > p, .textAndHeadingContainer > p {
    margin-right: 30%;
  }
}