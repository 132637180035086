.FooterComponent {
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 0 60px 0;
  width: 100%;
  background-color: #0D1234;
}

.FooterLogoAndText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FooterLogoAndText > img {
  width: 215px;
  height: auto;
}

.FooterNavContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 88px 0px;
  text-align: center;
  gap: 80px;
}

.FooterNavContainer > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerNav {
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 18px;
}

.FooterNavContainer > div h4 {
  color: #FFFEFC;
}

.FooterNavContainer > div p {
  color: #F1F1ED;
}

.footerNav:hover {
  text-decoration: underline;
}

.socialsContainer > img {
  cursor: pointer;
}

.hideOnMobile {
  display: none !important;
}

.showOnMobile {
  display: flex;
}

.newsletterDiv {
  color: #FFF;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 0;
  padding-bottom: 64px;
  gap: 44px;
}

.newsletterDiv > div {
  display: flex;
  padding: 0;
  gap: 8px;
}

.newsletterDiv > div p {
  font-size: 18px;
  line-height: 28px;
}

.subscribeContainer {
  align-items: center;
  gap: 24px !important;
}

.subscribeContainer > input {
  font-family: 'Inter';
  line-height: 12px;
  width: 70%;
  padding: 0px 16px;
  height: 48px;
  border-radius: 6px;
  border: none;
}

.subscribeContainer > input:focus {
  outline: none;
  border: none;
}

.subscribeContainer > input::placeholder {
  font-family: 'Inter';
}

.subscribeContainer > button {
  padding: 12px 16px;
  font-size: 16px;
  max-height: 48px;
}

.footerDecorationDiv {
  width: 100%;
  height: 1px;
  background-color: #374151;
}

.footerLastContainer {
  padding: 0;
  justify-content: center;
  align-items: center;
  color: #CECDC7;
  margin-top: 60px;
  gap: 64px;
}

.footerLastContainer > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#footerLogo {
  width: 70%;
}

.socialsContainer {
  display: flex;
  flex-direction: row !important;
  justify-content: space-evenly;
}

.linksContainer {
  width: 80% !important;
  flex-direction: column !important;
  color: #D1D5DB;
  gap: 24px !important;
  justify-content: space-evenly;
  text-align: center;
}

.linksContainer > a {
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px solid #D1D5DB;
}

.footerLastContainer p {
  font-size: 14px;
  white-space: nowrap;
}

.faleConoscoDiv {
  padding: 80px 21px;
  background-color: #E36925;
  color: #FFF;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px
}

.faleConoscoDiv > h3 {
  font-weight: 700;
  font-size: 28px;
}

.faleConoscoDiv > p {
  font-weight: 500;
  font-size: 18px;
}

.faleConoscoDiv > div {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  width: auto;
  gap: 40px;
}

.faleConoscoDiv > div button {
  text-align: center;
  
}

@media (min-width: 1024px) {
  .faleConoscoDiv {
    padding: 80px 19%;
  }

  .faleConoscoDiv > h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 150%;
  }
  
  .faleConoscoDiv > p {
    font-weight: 500;
    font-size: 24px;
  }

  .faleConoscoDiv > div {
    flex-direction: row;
  }

  .FooterComponent {
    width: calc(100% - 18%);
    padding: 0px 9% 55px 9%;
    justify-content: space-between;
  }

  .FooterNavContainer {
    align-items: flex-start;
    margin: 40px 0 0px 0px;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 6%;
  }

  .FooterLogoAndText {
    align-items: flex-start;
  }

  .socialsContainer {
    margin-top: 0;
    margin-bottom: 32px;
    gap: 32px;
    justify-content: space-between;
    align-self: flex-end;
  }

  .hideOnMobile {
    display: flex !important;
  }

  .showOnMobile {
    display: none !important;
  }

  .subscribeContainer {
    gap: 8px !important;
  }

  .newsletterDiv {
    gap: 0;
    text-align: start;
    align-items: flex-start;
  }

  .newsletterDiv > div {
    max-width: 490px;
  }

  .newsletterDiv > div p {
    font-size: 22px;
    line-height: 28px;
  }

  .subscribeContainer > input {
    width: 57%;
    max-height: 48px;
  }

  .subscribeContainer > button {
    max-height: 48px;
    width: 49%;
  }

  .footerLastContainer {
    justify-content: space-between;
    margin-top: 24px;
    gap: 0;
  }

  .footerLastContainer > div {
    width: auto !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
  }

  .linksContainer {
    flex-direction: row !important;
  }
}
