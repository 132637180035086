.complianceFirstContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 40px;
  padding-top: 64px;
  padding-bottom: 76px;
}

.complianceTextContainer {
  margin-top: 38px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.greyText {
  color: #6B7280;
  font-size: 16px;
  line-height: 150%;
  text-align: start;
}

.documentsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 80px;
  text-align: center;
}

.complianceSecondContainer {
  padding: 0 21px;
  margin-bottom: 80px;
}

.documentDownloadContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
  background: #FFFFFF;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.1);
  border-radius: 10px;
  padding: 24px 32px;
  height: 175px;
}

.documentDownloadContainer > .textAndImage {
  width: 100%;
}

#downloadLink {
  align-self: flex-end;
  justify-self: flex-end;
  text-decoration: none;
  margin-left: auto;
}

.downloadBtn {
  padding: 10px 18px;
  gap: 8px
}

.downloadBtn > img {
  height: 16px;
  width: 16px;
}

.downloadBtn > p {
  font-size: 14px;
  text-decoration: none transparent;
}

.documentsContainer > div {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.complianceThirdContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 21px;
  margin-right: 21px;
  margin-bottom: 150px;
}

.complianceData {
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-align: center;
}

.complianceData > * {
  margin: 0;
  margin-left: auto;
  margin-right: auto;
}

.complianceData > button {
  padding: 12px 31px;
  font-size: 16px;
  color: #FFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.complianceDisclaimer {
  background: #F1F1ED;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  padding: 24px 28px;
  text-align: start;
  font-size: 14px;
  line-height: 21px;
  color: #0D1234;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.complianceDisclaimer > ul {
  list-style-type: none;
  background-image: url('../../Media/Compliance/disclaimer.svg');
  background-repeat: no-repeat;
  padding-left: 32px;
  background-position-y: 0%;
}

.complianceDisclaimer > li::before {
  padding-top: 25px;
}

.complianceForm {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 32px
}

.complianceForm > .inputLabel {
  width: 100%;
  text-align: start;
}

#formHelpText {
  display: none;
  position: absolute;
  padding: 8px 12px;
  left: 100%;
  margin-left: 25px;
  background-color: #232952;
  color: #FFF;
  min-width: 450px;
  border-radius: 6px;
}

.complianceFormHelper {
  margin-left: 0;
  display: flex;
  flex-direction: row;
  gap: 14px;
  position: relative;
}

#formHelp {
  cursor: pointer;
}

#formHelp:hover + #formHelpText {
  display: inline;
}

#textAreaInput {
  margin-left: 0;
  margin-right: 0;
  text-align: start;
}

#textAreaInput > textarea {
  height: 250px;
}

.goBackText {
  background-image: url('../../Media/Compliance/goBackArrow.svg');
  cursor: pointer;
  background-repeat: no-repeat;
  background-position-y: 50%;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  text-decoration: underline;
  color: #0D1234;
}

.goBackText > ul {
  list-style-image: none;
  list-style-type: none;
}

.CompliancePage > .ComplianceTextPage .textAndHeadingContainer {
  gap: 20px
}

@media (min-width: 1024px) {
  .complianceFirstContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    text-align: start;
    padding-top: 100px;
  }

  .documentDownloadContainer {
    height: auto;
  }
  
  .documentDownloadContainer > .textAndImage {
    width: auto;
  }

  .documentsContainer {
    text-align: start;
    gap: 104px
  }

  #downloadLink {
    margin-left: auto;
  }

  .documentsContainer > div {
    width: 45%;
  }

  .complianceSecondContainer {
    padding: 0 7.5%;
    margin-bottom: 230px;
  }

  .greyText {
    max-width: 80%;
  }

  .complianceThirdContainer {
    margin-left: auto;
    margin-right: auto;
    max-width: 60%;
  }

  .complianceDisclaimer > ul {
    background-position-y: 25%;
  }

  .complianceForm > .inputLabel {
    min-width: 35%;
    max-width: 47%;
    text-align: start;
  }
}