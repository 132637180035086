.SolucoesMain {
  text-align: center;
}

.SolucoesMain li {
  list-style-image: url('../../Media/ulDecoration.svg');
}

.solucoesFirstContainer {
  padding: 64px 21px 48px 21px;
  justify-content: space-between;
  gap: 64px;
}

.solucoesFirstContainer > .imageContainer {
  position: relative;
  max-height: 415px;
}

.solucoesFirstContainer > .imageContainer img {
  max-height: 415px;
}

.solucoesFirstContainerText {
  max-width: 481px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-align: center;
}

.solucoesFirstContainerText p {
  font-size: 16px;
}

.solucoesFirstContainerText h2 {
  font-size: 20px;
}

.arrowImageControls {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 40%;
  left: 0;
}

.arrowImageControls > img {
  margin-left: 26px;
  margin-right: 26px;
  height: 30px;
  width: auto;
  cursor: pointer;
}

.currentImageHandler {
  position: absolute;
  bottom: 24px;
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.imageSelector {
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background-color: #E5E7EB;
  transition: 0.25s;
  cursor: pointer;
}

.currentImage {
  background-color: #E36925;
}

.solucoesSecondContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 164px;
}

.twoSolutionsContainer {
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
}

.twoSolutionsContainer > div {
  width: auto;
  background: #FAF9F6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  padding: 40px 40px 32px 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  text-align: start;
}

.twoSolutionsContainer > div button {
  align-self: flex-end;
}

.twoSolutionsContainer > div li {
  text-align: start;
  margin-bottom: 16px;
  font-size: 14px;
}

.solutionHeaderAndImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.solucoesThirdContainer {
  margin-top: 62px;
  margin-bottom: 152px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  justify-content: space-between;
}

.solucoesThirdContainer > div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: auto;
  align-items: center;
}

.solucoesThirdContainer > div img {
  width: 56px;
  height: auto;
}

.solucoesLastContainer {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 64px;
  margin-bottom: 150px;
}

.solucoesLastContainer > div {
  display: flex;
  flex-direction: column;
  gap: 40px
}

.solucoesLastContainer > div h3 {
  text-align: center;
}

.solucoesLastContainer > div li {
  margin: 0;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 150%;
}

.solucoesLastContainer > div ul {
  padding: 0;
  padding-left: 22px;
}

@media (min-width: 1024px) {

  .solucoesFirstContainer {
    padding: 70px 8% 0px 11%;
    margin-bottom: 48px;
  }

  .solucoesFirstContainerText {
    gap: 24px;
    margin-top: 30px;
    text-align: start;
  }

  .solucoesFirstContainerText p {
    font-size: 20px;
  }
  
  .solucoesFirstContainerText h2 {
    font-size: 24px;
  }

  .arrowImageControls > img {
    margin-left: 12px;
    margin-right: 12px;
    height: 40px;
    width: auto;
  }

  .imageSelector {
    width: 16px;
    height: 16px;
  }

  .currentImageHandler {
    bottom: 48px;
  }

  .solucoesSecondContainer {
    gap: 54px;
    margin-bottom: 212px;
  }

  .twoSolutionsContainer {
    flex-direction: row;
  }

  .solutionHeaderAndImageContainer {
    flex-direction: row;
    justify-content: space-between;
  }

  .twoSolutionsContainer > div {
    gap: 36px;
    width: 40%;
  }

  .twoSolutionsContainer > div li {
    font-size: 18px;
  }

  .solucoesThirdContainer {
    margin-top: 96px;
    margin-bottom: 168px;
    flex-direction: row;
    gap: 72px;
  }

  .solucoesThirdContainer > div {
    gap: 40px;
    width: 20%;
  }

  .solucoesThirdContainer > div img {
    width: 64px;
    height: auto;
  }

  .solucoesLastContainer {
    display: flex;
    flex-direction: row;
    padding: 0 15%;
    gap: 220px;
    margin-bottom: 170px;
  }

  .solucoesLastContainer > div {
    display: flex;
    flex-direction: column;
    max-width: 40%;
  }
  
  .solucoesLastContainer > div h3 {
    text-align: start;
  }

  .solucoesLastContainer > div li {
    font-size: 18px;
  }
  
}