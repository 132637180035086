.modal {
  width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow-x: visible;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: absolute;
}

.aaaaaaaa1 {
  border: 5px solid #E36925;
  background-color: #FFF;
  max-height: 80%;
  padding: 25px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  border-radius: 6px;
  padding-top: 100px;
  position: relative;
  z-index: 10;
}

.aaaaaaaa1 > button {
  position: absolute;
  top: 25px;
  right: 0;
  z-index: 10000;
}

.aaaaaaaa1 > div {
  margin-top: 25px;
}

@media (min-width: 1024px) {

  .aaaaaaaa1 {
    padding-top: 0;
  }
}