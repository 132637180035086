.homeFirstContainer {
  padding-top: 36px;
  margin-bottom: 160px;
  display: flex;
  flex-direction: column;
  gap: 76px;
}

.HomeMain > h3 {
  text-align: center;
  padding: 0 21px 0 21px;
}

.HomeMain > .aboutThirdContainer {
  margin-bottom: 80px;
}

.orgaosContainer {
  margin-top: 64px;
  margin-bottom: 181px;
  width: calc(100% - 182px);
  height: 340px;
  margin-left: 91px;
  margin-right: 91px;
  background-image: url('../../Media/orgaosMobile.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.homeVideoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}

.homeVideoContainer > iframe {
  border: none;
  width: 92%;
  min-height: 165px;
  border-radius: 6px;
}

.homeFirstContainerText {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  text-align: center;
}

.homeFirstContainerText > img {
  max-width: 67px;
  height: auto;
}

.homeFirstContainerText > button {
  max-width: 205px;
}

.homeSecondContainer {
  margin-top: 48px;
  margin-bottom: 72px;
  display: flex;
  flex-direction: column;
  gap: 140px;
  justify-content: space-evenly;
}

.homeSecondContainer > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
}

.homeThirdContainer {
  margin-top: 48px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
}

.threeServicosRow {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  justify-content: space-between;
}

.threeServicosRow > div {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  padding: 40px 27px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  text-align: center;
  background-color: #FAF9F6;
}

.threeServicosRow > div > img {
  width: 80px;
  height: 80px;
}

.twoButtonsDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
}

.twoButtonsDiv > button {
  height: 48px;
  border-radius: 6px;
}

.twoButtonsDiv > .buttonWithArrow {
  color: #FFFFFF;
  border: none;
  background-color: #E36925;
  padding: 0 16px;
  height: 48px;
  width: auto;
}

.twoButtonsDiv > .buttonWithArrow p {
  font-size: 16px;
}

.twoButtonsDiv > .smallBtn {
  min-height: 48px !important;
  min-width: 48px !important;
}

.carouselImages {
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.carouselImages::-webkit-scrollbar {
  display: none; 
}

.imageContainerHome {
  height: 100%;
}

.carouselImage {
  width: auto;
  height: 100%;
  /* max-width: 100%; */
}

.carouselImages {
  height: 320px;
  transition: 1s;
}

.carouselContainer {
  scroll-behavior: smooth;
  /* height: 320px; */
  overflow: scroll;
  transition: 1s;
}

.carouselContainer::-webkit-scrollbar {
  display: none; 
}

.mouseOverImage {
  transition: all .5s ease;
}

.decorationDiv {
  text-align: center;
  font-size: 14px;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color:#F1F1ED;
  top: 50%;
  left: 0;
  padding: 24px 0;
}

.decorationDiv > p {
  font-size: 16px;
}

.blogClickDiv {
  color: #FFFFFF;
  background-color: #E36925;
  top: 48%;
  padding: 10px 16px;
  left: auto;
  border-radius: 6px;
  right: auto;
  text-align: center;
  position: absolute;
  display: none;
}

@media (min-width: 1024px) {
  .imageContainerHome {
    height: auto;
  }

  .carouselContainer {
    width: 55%;
    height: 676px;
    margin-top: 81px;
  }

  .carouselImages {
    flex-direction: column;
    height: auto;
  }

  .carouselImage {
    width: 100%;
    height: auto;
  }

  .threeServicosRow > div {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    width: 30%;
    justify-content: space-between;
    padding: 58px 42x 42px 42px;
  }

  .threeServicosRow {
    display: flex;
    flex-direction: row;
  }

  .homeThirdContainer {
    margin-top: 80px;
    margin-bottom: 100px;
  }

  .homeSecondContainer {
    margin-top: 100px;
    margin-bottom: 88px;
    flex-direction: row;
  }

  .homeFirstContainer {
    padding-top: 0;
    padding-right: 40px;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .homeFirstContainerText {
    margin-top: 96px;
    align-items: flex-start;
    text-align: start;
  }

  .homeFirstContainerText > button {
    max-width: 233px;
  }

  .homeFirstContainerText > img {
    max-width: 120px;
    height: auto;
  }

  .homeVideoContainer > iframe {
    min-height: 600px;
  }

  .homeVideoContainer {
    margin-bottom: 132px;
  }

  .orgaosContainer {
    margin-top: 80px;
    margin-bottom: 200px;
    width: calc(100% - 560px);
    height: 150px;
    margin-left: 17%;
    margin-right: 17%;
    background-image: url('../../Media/orgaosDesktop.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  
}