a {
  cursor: pointer;
}

.HeaderComponent {
  overflow: visible;
  color: #FFF;
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  top: 0;
  z-index: 5;
}

.HeaderComponent > .showOnMobile {
  background-color: #0D1234;
  position: absolute;
  z-index: 10;
  top: 60px;
  text-align: center;
}

.DesktopHeader {
  display: none;
}

.pamHeaderLogo > img {
  width: 100%;
  height: auto;
}

.MobileHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #0D1234;
}

#currentPage {
  height: auto !important;
  padding: 10px 0;
  background-color: #E36925;
  color: #FFF;
}

#currentPage:hover {
  transition: 0.5s;
  background-color: #ae5728;
}

.mobileHeaderStartingItens {
  min-height: 60px;
  width: calc(100% - 36px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
}

.pamHeaderLogo {
  cursor: pointer;
  width: 131px;
}

.desktopHeaderItens > p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 44px;
  margin: 0;
  font-weight: 600;
}

.desktopHeaderItens {
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-wrap: wrap;
}

#headerBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  padding: 15px 20px;
}

#headerBtn > p {
  margin: 0;
  color: #FFF;
}

#currentPageDesktop {
  color: #F49A6D;
  font-weight: 700;
  border-bottom: 1px solid #F49A6D;
}

@media (min-width: 1024px) {
  .MobileHeader {
    display: none;
  }

  .DesktopHeader {
    width: calc(100% - 80px);
    background-color: #0D1234;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 21px 40px;
  }

  .desktopHeaderItens {
    padding: 4px 6px;
    width: calc(100% - 6px);
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: flex-start;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 26px;
  }

  .desktopHeaderItens {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .desktopHeaderItens > p {
    cursor: pointer;
    width: auto;
    transition: 0.2s;
    height: auto;
  }

  .desktopHeaderItens > p:hover {
    color: #F49A6D;
    font-weight: 700;
    border-bottom: 1px solid #F49A6D;
  }
}
